/**
 * If you edit this file please also reflect the changes in
 * `backend/src/utils/dates/getStartOfDay.ts`
 */
export function getStartOfDay(date: Date): Date {
  const twentyFourHoursInMilliseconds = 86400000;

  const dateInMilliseconds = date.getTime();
  const startOfFirstDayMilliseconds = dateInMilliseconds - (dateInMilliseconds % twentyFourHoursInMilliseconds);

  return new Date(startOfFirstDayMilliseconds);
}
