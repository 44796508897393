/**
 * If you edit this file please also reflect the changes in
 * `backend/src/utils/dates/addDays.ts`
 */
export function addDays(currentDate: Date, days: number): Date {
  const date = new Date(currentDate.valueOf());
  date.setDate(date.getDate() + days);

  return date;
}
