import { useSlotRequestCacheKeysStore } from "~/store/slotRequestCacheKeys";
import type { SlotsResponse } from "~/types/api/Slots";
import { addDays } from "~/utils/dates/addDays";
import { getEndOfDay } from "~/utils/dates/getEndOfDay";
import { getStartOfDay } from "~/utils/dates/getStartOfDay";
import type { FetchWithCancelResponse } from "~/utils/fetchWithCancel";
import { fetchWithCancel } from "~/utils/fetchWithCancel";
import { isNullOrUndefined } from "~/utils/typeGuards";

/**
 * This file is copied to
 * `backend/src/api/service/util/schedulerSlots.service.ts`
 * If you edit this file please also adapt the other file to reflect the changes
 */
export const SCHEDULER_DAYS_INTERVAL = 14;
// The first day is also already a day, so we don't need to calculate +14 but +13
export const AMOUNT_OF_DAYS_TO_ADD_TO_CURRENT_DAY = SCHEDULER_DAYS_INTERVAL - 1;

export type SlotPerService = {
  [key: number]: Date | null;
};

type ResponseWithCacheKey<T> = {
  cacheKey: string;
  data: T;
};

/**
 * Fetches the slots for a specific time interval
 * If you want to make sure to get a time interval use the function
 * fetchSlotsFromDateAndMoreIfNoneFound
 * returns null on error
 */
type FetchSlotsFromDateParams = {
  from: Date;
  invalidateCache?: boolean;
  locationId?: number;
  service?: number;
  user?: number;
};

export function fetchSlotsFromDate({
  from,
  invalidateCache = false,
  locationId,
  service,
  user,
}: FetchSlotsFromDateParams): Promise<FetchWithCancelResponse<SlotsResponse> | null> {
  if (service !== undefined && service <= 0) {
    return Promise.resolve(null);
  }

  const isoFrom = getStartOfDay(from).toISOString();
  const isoTo = getEndOfDay(addDays(from, AMOUNT_OF_DAYS_TO_ADD_TO_CURRENT_DAY)).toISOString();

  const params = new URLSearchParams({
    from: isoFrom.toString(),
    to: isoTo.toString(),
  });
  const serviceString = service?.toString(10);
  const userString = user?.toString(10);
  const locationIdString = locationId?.toString(10);

  if (serviceString) {
    params.set("service", serviceString);
  }
  if (userString) {
    params.set("user", userString);
  }
  if (locationIdString) {
    params.set("location", locationIdString);
  }
  if (invalidateCache) {
    // when invalidating the cache we also want to force the request to
    // make sure we invalidate it
    params.set("shouldInvalidateCache", "true");
  }

  return fetchWithCancel<ResponseWithCacheKey<SlotsResponse> | SlotsResponse>(
    `/api/scheduler/slots?${params.toString()}`,
    invalidateCache,
  )
    .then((response) => {
      if (response === undefined || response === null) {
        return null;
      }

      if ("cacheKey" in response.data) {
        if (import.meta.client === true) {
          const slotRequestStore = useSlotRequestCacheKeysStore();
          slotRequestStore.setKey(serviceString, userString, response.data.cacheKey);
        }

        return {
          cancel: response.cancel,
          data: response.data.data,
        };
      }

      return {
        cancel: response.cancel,
        data: response.data,
      };
    })
    .catch((error) => {
      const emptyResponse: SlotsResponse = { meta: null, slots: [] };

      if (error.name !== "FetchError") {
        throw error;
      }

      return {
        cancel: () => {},
        data: emptyResponse,
      };
    });
}

export async function fetchSlotsFromDateAndMoreIfNoneFound({
  from,
  service,
  user,
  locationId,
  invalidateCache = false,
}: {
  from: Date | null;
  service?: number;
  user?: number;
  locationId?: number;
  invalidateCache?: boolean;
}): Promise<FetchWithCancelResponse<SlotsResponse> | null> {
  if (from === null) {
    return null;
  }

  const timeIntervalSlots = await fetchSlotsFromDate({ from, invalidateCache, locationId, service, user });

  if (timeIntervalSlots?.data === null || timeIntervalSlots?.data === undefined) {
    return null;
  }

  if ("slots" in timeIntervalSlots.data && timeIntervalSlots.data.slots.length > 0) {
    return timeIntervalSlots as FetchWithCancelResponse<SlotsResponse>;
  }

  if (!("meta" in timeIntervalSlots.data) || !timeIntervalSlots.data.meta?.nextSlot) {
    return null;
  }

  const fromNextSlot = new Date(Date.parse(timeIntervalSlots.data.meta.nextSlot));

  const nextTimeIntervalSlots = await fetchSlotsFromDate({ from: fromNextSlot, invalidateCache, service, user });

  if (nextTimeIntervalSlots === null) {
    return null;
  }

  if (!("slots" in nextTimeIntervalSlots.data)) {
    return null;
  }

  return nextTimeIntervalSlots as FetchWithCancelResponse<SlotsResponse>;
}

type FetchNextAvailableSlotsPerServiceParams = {
  from?: Date;
  locationId?: number;
  serviceIds?: number[];
  user?: number;
};

export async function fetchNextAvailableSlotsPerService({
  from,
  locationId,
  serviceIds,
  user,
}: FetchNextAvailableSlotsPerServiceParams): Promise<SlotPerService> {
  if (serviceIds === undefined) {
    const nextAvailableSlot = await fetchNextAvailableSlotForServiceAndUser({
      fromDate: from,
      invalidateCache: false,
      locationId,
      userId: user,
    });

    return [nextAvailableSlot];
  }

  if (serviceIds.length === 1) {
    const serviceId = serviceIds[0];
    const nextAvailableSlot = await fetchNextAvailableSlotForServiceAndUser({
      fromDate: from,
      invalidateCache: false,
      locationId,
      serviceId,
      userId: user,
    });
    const returnArray = [];
    returnArray[serviceId] = nextAvailableSlot;

    return returnArray;
  }

  const fetchAllTimeIntervals = serviceIds.map((serviceId) =>
    fetchNextAvailableSlotForServiceAndUser({
      fromDate: from,
      invalidateCache: false,
      locationId,
      serviceId,
      userId: user,
    }),
  );

  const responses = await Promise.all([...fetchAllTimeIntervals]);

  return responses.reduce<SlotPerService>((previousValue, currentValue, key) => {
    const serviceId = serviceIds[key];
    const returnArray = previousValue ?? [];
    returnArray[serviceId] = currentValue;

    return returnArray;
  }, {});
}

type FetchNextAvailableSlotForServiceAndUserParams = {
  fromDate?: Date;
  invalidateCache?: boolean;
  locationId?: number;
  serviceId?: number;
  userId?: number;
};

export async function fetchNextAvailableSlotForServiceAndUser({
  fromDate,
  invalidateCache = false,
  locationId,
  serviceId,
  userId,
}: FetchNextAvailableSlotForServiceAndUserParams): Promise<Date | null> {
  const fromStartOfToday = fromDate || new Date(Date.now());

  const slotsResponse = await fetchSlotsFromDate({
    from: fromStartOfToday,
    invalidateCache,
    locationId,
    service: serviceId,
    user: userId,
  });

  if (slotsResponse?.data === null || slotsResponse?.data === undefined) {
    return null;
  }

  if (slotsResponse.data.slots === undefined || slotsResponse.data.slots.length === 0) {
    if ("meta" in slotsResponse.data) {
      if (isNullOrUndefined(slotsResponse.data.meta?.nextSlot)) {
        return null;
      }

      return new Date(slotsResponse.data.meta.nextSlot);
    }

    return null;
  }

  const firstSlotDate = slotsResponse.data.slots[0].date;

  return getStartOfDay(new Date(firstSlotDate));
}
